.filter-item-head {
    // Các kiểu dáng cơ bản
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px;
    cursor: pointer;

    &.selected {
        // Kiểu dáng cho phần tử được chọn
        background-color: #007bff1f; // Ví dụ: thay đổi màu nền khi được chọn
        color: #fff; // Ví dụ: thay đổi màu chữ khi được chọn
        // border-color: #007bff; // Ví dụ: thay đổi màu viền khi được chọn
    }

    .filter-head-title {
        // Kiểu dáng cho tiêu đề của phần tử
        font-size: 16px;
        font-weight: normal;
    }

    .filter-head-icon {
        // Kiểu dáng cho biểu tượng
        font-size: 20px;
    }
}

// Kiểu dáng cho các phần tử lọc
.filter-wrap {
    &.show {
        display: block;
    }

    &.hide {
        display: none;
    }
}


//--------------------------login để đăng nhập----------------------------------------

/* Giao diện khi chưa đăng nhập */
.login-prompt {
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    color: #333;
}

.login-message {
    margin-bottom: 1rem;
    color: #666;
}

.login-prompt .btn-secondary {
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    text-decoration: none;
    display: inline-block;
}

.login-prompt .btn-secondary:hover {
    background-color: #0056b3;
}

//-----------------nút xóa---------------------------------------------------------
.clear-filter-btn {
    background: transparent;
    border: none;
    color: #888;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
}

.clear-filter-btn:hover {
    color: #333;
}

.product-filter-item {
    position: relative;
}

.clear-filter-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    border: none;
    color: #888;
    font-size: 14px;
    cursor: pointer;
}

.clear-filter-btn:hover {
    color: #333;
}

.no-results {
    text-align: center;
    color: #888;
    font-size: 1rem;
    margin: 1rem 0;
  }