.feedback-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* Khoảng cách giữa các feedback item */
    padding: 1rem;
    background-color: #f9f9f9;
    /* Màu nền của container feedback */
}

.feedback-item {
    display: flex;
    flex-direction: column;
    background: #fff;
    /* Màu nền của mỗi feedback item */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feedback-header {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    /* Khoảng cách giữa avatar và nội dung */
    padding: 1rem;
}

.avatar-container {
    width: 50px;
    /* Kích thước của avatar */
    height: 50px;
    /* Kích thước của avatar */
    overflow: hidden;
    border-radius: 50%;
    /* Đảm bảo hình ảnh có dạng tròn */
}

.feedback-content {
    flex: 1;
    /* Cho phép phần nội dung chiếm hết không gian còn lại */
    background-color: #f1f1f1;
    /* Màu nền của khối nội dung */
    padding: 0.5rem 1rem;
    border-radius: 8px;
}

.user-info {
    margin-bottom: 0.5rem;
}

.user-name {
    font-weight: bold;
    font-size: 1rem;
    color: #333;
}

.feedback-comment {
    font-size: 0.9rem;
    color: #555;
}

.user-rating {
    margin-top: 5px;
}

.user-rating .star-rating {
    .star-rating-star {
        color: #4CAF50;
        /* Màu xanh lá cây */
    }
}

//--------------------------
.stars-container {
    margin: 20px 0;
}

.star-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.star-label {
    width: 100px;
    font-weight: bold;
}

.star-bar {
    flex: 1;
    height: 20px;
    background-color: #e0e0e0;
    position: relative;
    border-radius: 10px;
}

.star-fill {
    height: 100%;
    background-color: #f39c12;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.3s ease;
}

//-------------------summary 
/* Tổng quan đánh giá */
.rating-summary {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rating-summary-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5rem;
}

.rating-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0;
    display: flex;
    align-items: center;
}

.rating-icon {
    margin-right: 8px;
    color: #f39c12;
}

.rating-info {
    margin-top: 0.5rem;
}

.average-rating {
    font-size: 1.5rem;
    font-weight: bold;
    color: #f39c12;
}

.total-ratings {
    font-size: 0.875rem;
    color: #333;
}

/* Hiển thị đánh giá sao */
.stars-container {
    margin-top: 1rem;
}

.star-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.star-label {
    width: 80px;
    font-weight: bold;
}

.star-bar {
    flex: 1;
    height: 15px;
    background-color: #e0e0e0;
    position: relative;
    border-radius: 10px;
}

.star-fill {
    height: 100%;
    background-color: #f39c12;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.3s ease;
}
